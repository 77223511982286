import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "wir-sind-stets-im-einsatz-für-sie"
    }}>{`Wir sind stets im Einsatz für Sie.`}</h1>
    <p><strong parentName="p">{`Unser Service für Sie:`}</strong></p>
    <ul>
      <li parentName="ul">{`Heizung, Lüftung, Sanitär, Kälte, Klima, Senioren- und Behindertengerechte Installation`}</li>
      <li parentName="ul">{`Ofenbau, Fliesen`}</li>
      <li parentName="ul">{`Solar, Wärmepumpen, Regenwassernutzung`}</li>
      <li parentName="ul">{`Feuerlösch- und Entrauchungsanlagen, Luftmengenmessung`}</li>
      <li parentName="ul">{`Kundendienst, Wartungen, Legionellenbekämpfung in kontaminierten Anlagen`}</li>
      <li parentName="ul">{`Vermietung (Notstromaggregate, Spülkompressoren, C-Schlauch-Pumpen, Schlammsauger,...)`}</li>
    </ul>
    <p><img alt="Service" src={require(".//images/uploads/service.svg")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      